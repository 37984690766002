/*
Main search form on index page
*/

import React, { useState } from "react";
import Select from "react-select";
import { navigate } from "gatsby";
import queryString from "query-string";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

const SearchForm = () => {
  const [state, setState] = useState({
    region: null,
    property_type: null,
    size: null,
    search: "",
  });

  const handleInputChange = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const locations = [
    { value: "10", label: "Toscana" },
    { value: "11", label: "Umbria" },
    { value: "9", label: "Marche" },
    { value: "12", label: "Lazio" },
    { value: "19", label: "Sisilia" },
    { value: "pohjois_italiaa", label: "Pohjois-Italiaa" },
    { value: "13", label: "Amalfin Alue" },
    { value: "other", label: "Muut" },
  ];
  const holidayTypes = [
    { value: "4", label: "Villa" },
    { value: "6", label: "Luxus" },
    { value: "7", label: "Juhlahuvila" },
    { value: "1", label: "Rannikko" },
    { value: "8", label: "Viinitila" },
    { value: "5", label: "Huoneisto" },
    { value: "9", label: "Hotelli" },
    { value: "", label: "Kaikki" },
  ];
  const sizes = [
    { value: "2:4", label: "2-4 vierasta" },
    { value: "5:7", label: "5-7 vierasta" },
    { value: "8:9", label: "8-9 vierasta" },
    { value: "10:13", label: "10-13 vierasta" },
    { value: "14:26", label: "14-26 vierasta" },
    { value: "27:54", label: "27-54 vierasta" },
    { value: "", label: "Kaikki" },
  ];

  let isFormEmpty =
    !state.search && !state.region && !state.property_type && !state.size;

  return (
    <form>
      <div className="form-row align-items-center p-2">
        <div className="col-lg-12">
          <div className="input-group">
            <div className="input-group-prepend">
              <div
                className="input-group-text"
                style={{
                  backgroundColor: "white",
                  paddingRight: "0.5px",
                }}
              >
                <FontAwesomeIcon
                  icon={faSearch}
                  className="text-muted"
                  fixedWidth
                  size="sm"
                />
              </div>
            </div>
            <input
              className="form-control"
              style={{ borderLeftColor: "white" }}
              type="text"
              name="search"
              placeholder="Hae nimellä"
              value={state.search}
              onChange={event => {
                handleInputChange(event.target.value, event.target.name);
              }}
            />
          </div>
        </div>
        <div className="w-100"></div>

        <Select
          className="col-sm pt-1"
          placeholder="Maakunta"
          name="region"
          value={state.region}
          options={locations}
          onChange={(value, event) => {
            handleInputChange(value, event.name);
          }}
          // defaultMenuIsOpen={true}
        />

        <div className="w-100"></div>

        <Select
          className="col-sm pt-1"
          placeholder="Majoitustyyppi"
          name="property_type"
          value={state.property_type}
          options={holidayTypes}
          onChange={(value, event) => {
            handleInputChange(value, event.name);
          }}
        />
        <Select
          // isDisabled
          className="col-sm pt-1"
          name="size"
          value={state.size}
          placeholder="Vieraiden määrä"
          options={sizes}
          onChange={(value, event) => {
            handleInputChange(value, event.name);
          }}
        />
        {/* <div className="w-100"></div>

        <div className="col-lg-6 pt-1">
          <div className="input-group">
            <div className="input-group-prepend">
              <div
                className="input-group-text"
                style={{
                  backgroundColor: "white",
                  paddingRight: "0.5px",
                }}
              >
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  className="text-muted"
                  fixedWidth
                  size="sm"
                />
              </div>
            </div>
            <input
              className="form-control"
              style={{ borderLeftColor: "white" }}
              type="text"
              name="date-from-search"
              placeholder="Tulo"
            />
          </div>
        </div>

        <div className="col-lg-6 pt-1">
          <div className="input-group">
            <div className="input-group-prepend">
              <div
                className="input-group-text"
                style={{
                  backgroundColor: "white",
                  paddingRight: "0.5px",
                }}
              >
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  className="text-muted"
                  fixedWidth
                  size="sm"
                />
              </div>
            </div>
            <input
              className="form-control"
              style={{ borderLeftColor: "white" }}
              type="text"
              name="date-to-search"
              placeholder="Lähtö"
            />
          </div>
        </div> */}

        <div className="w-100"></div>
        <div className="col-lg-12 pt-3">
          <button
            className={`btn ${
              isFormEmpty ? "btn-outline-primary" : "btn-primary"
            }  btn-block rounded-sm h-100`}
            type="submit"
            disabled={isFormEmpty}
            onClick={e => {
              e.preventDefault();
              navigate(
                "/search?" +
                  queryString.stringify({
                    region: state.region ? state.region.value : "",
                    property_type: state.property_type
                      ? state.property_type.value
                      : "",
                    size: state.size ? state.size.value : "",
                    search: state.search || "",
                  })
              );
            }}
          >
            Hae
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchForm;
