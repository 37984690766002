import React from "react";
import { Image } from "../image";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Section from "../components/section";
import SEO from "../components/seo";

import {
  IndexForm,
  MarketingColumns,
  ContactFormWithText,
  SwiperCards,
} from "../components/indexpage";

const IndexPage = ({
  data: { ehdotamme, gaiaWebsite, diamondDeals, lastMinuteDeals },
}) => {
  return (
    <Layout isIndexPage>
      <SEO title="Loma Italiassa - Talo Toscanassa!" />
      <Section
        padding={0}
        className={"hero-height-without-footer-on-desktop"}
        style={{ position: "relative" }}
        containerStyle={{ height: "100%" }}
      >
        <div className="dark-overlay bg-image">
          <Image
            layout="fullWidth"
            priority={true}
            src={`/_${gaiaWebsite.background.url}`}
            background={gaiaWebsite.background.placeholder}
            width={2560}
            alt="Gaia-Travels"
            className="img-fluid bg-image blog-title-image gatsby-image-wrapper"
          />
        </div>
        <div className="p-4 h-100">
          <div
            className="row z-index-20"
            style={{
              height: "100%",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="col-xl-12"
              style={{
                position: "relative",
              }}
            >
              <div
                className="text-center"
                // style={{ position: "relative", zIndex: 5 }}
              >
                <p
                  className="subtitle letter-spacing-4 mb-2 text-shadow"
                  style={{ color: "white" }}
                >
                  Laatutarkistetut kohteet Italiasta
                </p>
                <h1
                  className="display-4 d-none d-sm-block main-heading font-weight-bold text-shadow"
                  style={{ color: "white" }}
                >
                  Hae unelmahuvilasi!
                </h1>
              </div>
            </div>
            <IndexForm />
          </div>
        </div>
      </Section>

      <Section>
        <div>
          <SwiperCards
            type="vacation-card"
            cards={ehdotamme.child_pages}
            title="Valitse lomakohde teeman mukaan"
            subtitle="Ehdotamme"
          ></SwiperCards>
        </div>
      </Section>

      <Section>
        <SwiperCards
          type="property-card"
          cards={diamondDeals.nodes}
          title="Kauden suosikit ja uudet löydöt"
          subtitle="Timanttikohteet"
          seeAllUrl="/huvilat/timanttikohteet"
        ></SwiperCards>
      </Section>

      <div id="last-minute" className="anchor" />
      <Section>
        <SwiperCards
          type="property-card"
          cards={lastMinuteDeals.nodes}
          title="Viimehetken tarjoukset peruutushinnoilla"
          subtitle="Last minute deals"
          seeAllUrl="/huvilat/tarjoukset-last-minute"
        ></SwiperCards>
      </Section>

      <Section padding={0}>
        <div>
          <MarketingColumns
            title={gaiaWebsite.marketing_block.title}
            subtitle={gaiaWebsite.marketing_block.subtitle}
            left_column={gaiaWebsite.marketing_block.left_column}
            central_column={gaiaWebsite.marketing_block.central_column}
            right_column={gaiaWebsite.marketing_block.right_column}
          ></MarketingColumns>
        </div>
      </Section>

      <Section padding={0}>
        <div>
          <h2 className="h4 mb-5">Pikatiedustelu</h2>
          <ContactFormWithText text={gaiaWebsite.contact_form_message} />
        </div>
      </Section>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexQuery {
    gaiaWebsite {
      contact_form_message
      background {
        url
        placeholder
      }
      # fields {
      # background # {
      #   childImageSharp {
      #     fluid(maxWidth: 2560) {
      #       ...GatsbyImageSharpFluid
      #     }
      #   }
      # }
      # }
      marketing_block {
        title
        subtitle
        left_column {
          text
          title
        }
        central_column {
          text
          title
        }
        right_column {
          text
          title
        }
      }
    }
    ehdotamme: gaiaPage(name: { eq: "Ehdotamme" }) {
      child_pages {
        name
        url: target_url
        preview_picture {
          url
          placeholder
        }
        # fields {
        #   preview_picture {
        #     childImageSharp {
        #       fluid(maxWidth: 300, maxHeight: 750, cropFocus: SOUTH) {
        #         ...GatsbyImageSharpFluid
        #       }
        #     }
        #   }
        # }
      }
    }
    diamondDeals: allGaiaPage(
      limit: 10
      filter: { type: { eq: "property" }, diamond_deal: { eq: true } }
    ) {
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
        perPage
        itemCount
      }
      totalCount
      nodes {
        # fields {
        #   first_picture {
        #     childImageSharp {
        #       fluid(maxWidth: 450, maxHeight: 350) {
        #         ...GatsbyImageSharpFluid
        #       }
        #     }
        #   }
        # }
        diamond_deal
        has_discounts
        first_picture {
          url
          placeholder
        }
        property {
          marketing_name
          property_types
          starting_price
          occupancy
        }
        url
        name
      }
    }
    lastMinuteDeals: allGaiaPage(
      limit: 10
      filter: { has_discounts: { eq: true } }
    ) {
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
        perPage
        itemCount
      }
      totalCount
      nodes {
        # fields {
        #   first_picture {
        #     childImageSharp {
        #       fluid(maxWidth: 450, maxHeight: 350) {
        #         ...GatsbyImageSharpFluid
        #       }
        #     }
        #   }
        # }
        diamond_deal
        has_discounts
        first_picture {
          url
          placeholder
        }
        property {
          marketing_name
          property_types
          starting_price
          occupancy
        }
        url
        name
      }
    }
  }
`;
