/*
Contact form
*/

import React from "react";
import ContactForm from "./contactform";

const ContactFormWithText = ({ text }) => {
  return (
    <div className="row">
      <div className="col-md-7 mb-5 mb-md-0">
        <ContactForm />
      </div>
      <div className="col-md-5">
        <div className="pl-lg-4">
          <p className="text-muted">{text}</p>
        </div>
      </div>
    </div>
  );
};

export default ContactFormWithText;
