/*
Main search form on index page
*/

import React from "react";
import { Link } from "gatsby";
import SearchForm from "./searchform";

const IndexForm = () => {
  return (
    <div
      className="search-form p-1"
      style={{
        position: "relative",
        // zIndex: 5,
      }}
    >
      <ul
        className="nav nav-tabs pb-sm-10"
        style={{
          border: "0",
          justifyContent: "center",
        }}
      >
        <li className="nav-item">
          <a
            className="nav-link active text-uppercase border-right-0"
            style={{
              borderTopColor: "white",
              borderLeftColor: "white",
              borderRightColor: "lightgray",
              borderRadius: "0",
            }}
          >
            Huvilahaku
          </a>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <Link
            to="/car-rental"
            className="nav-link text-uppercase text-muted border-left"
            style={{
              borderLeftColor: "#dee2e6",
              borderBottomColor: "white",
              borderRadius: "0",
            }}
          >
            Autovuokraus
          </Link>
        </li>
      </ul>

      <div className="tab-content">
        <div className="tab-pane active" role="tabpanel">
          <SearchForm />
        </div>

        <div
          className="tab-pane active"
          id="autovuokraus"
          role="tabpanel"
          aria-labelledby="autovuokraus-tab"
        ></div>
      </div>
      <div className="d-block d-sm-none nav-item mt-2">
        <Link
          to="/car-rental"
          className="nav-link text-uppercase text-muted border-top text-center"
          style={{
            borderTopColor: "#dee2e6",
            borderRadius: "0",
          }}
        >
          Autovuokraus
        </Link>
      </div>
    </div>
  );
};

export default IndexForm;
