/*
Three columns with desc
*/

import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkedAlt,
  faCreditCard,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";

const MarketingColumns = ({
  title,
  subtitle,
  left_column,
  central_column,
  right_column,
}) => {
  return (
    <>
      <div className="text-center pb-lg-4">
        <p className="subtitle">{subtitle}</p>
        <h2 className="mb-5">{title}</h2>
      </div>
      <div className="row">
        <div className="col-lg-4 mb-3 mb-lg-0 text-center">
          <div className="px-0 px-lg-3">
            <div className="icon-rounded bg-primary-light text-primary mb-3">
              <FontAwesomeIcon icon={faMapMarkedAlt} fixedWidth size="2x" />
            </div>
            <h3 className="h5">{left_column.title}</h3>
            <div
              className="text-muted mb-3"
              dangerouslySetInnerHTML={{
                __html: left_column.text,
              }}
            ></div>
          </div>
        </div>
        <div className="col-lg-4 mb-3 mb-lg-0 text-center">
          <div className="px-0 px-lg-3">
            <div className="icon-rounded bg-primary-light text-primary mb-3">
              <FontAwesomeIcon icon={faCreditCard} fixedWidth size="2x" />
            </div>
            <h3 className="h5">{central_column.title}</h3>
            <div
              className="text-muted mb-3"
              dangerouslySetInnerHTML={{
                __html: central_column.text,
              }}
            ></div>
          </div>
        </div>
        <div className="col-lg-4 mb-3 mb-lg-0 text-center">
          <div className="px-0 px-lg-3">
            <div className="icon-rounded bg-primary-light text-primary mb-3">
              <FontAwesomeIcon icon={faHeart} fixedWidth size="2x" />
            </div>
            <h3 className="h5">{right_column.title}</h3>
            <div
              className="text-muted mb-3"
              dangerouslySetInnerHTML={{
                __html: right_column.text,
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingColumns;
